import React, { useState } from "react"
import Layout from "../../components/layout/layout"
import { useForm } from "react-hook-form"
import guardianCounselApi from "../../apis/guardianCounselApi"
import { Link } from "gatsby"

const CreateInvitation = () => {
  const { register, handleSubmit } = useForm()

  const [error, setError] = useState(null)

  const [status, setStatus] = useState("ready")

  const isReady = status === "ready"
  const isSubmitting = status === "submitting"
  const isSubmitted = status === "submitted"

  const formSubmit = async ({ email, firstName, lastName }) => {
    setStatus("submitting")

    try {
      await guardianCounselApi.post("/invitation", {
        email,
        firstName,
        lastName,
      })

      setStatus("submitted")
    } catch (err) {
      setError("Something went wrong trying to submit this form...")

      setStatus("ready")
    }
  }

  return (
    <Layout>
      {isReady && (
        <>
          <h3>Create New Invitation</h3>
          <hr />
          <form onSubmit={handleSubmit(formSubmit)}>
            <p>
              Enter the information for the person you'd like to invite. A new OneDrive folder will automatically be
              created for them.
            </p>
            <p>
              What you enter for their "First Name" is what we'll use to greet them in the automated emails and in the
              client side of the document portal.
            </p>

            {error && <div className="text-danger">{error}</div>}

            <div className="form-group">
              <label htmlFor="email">Email address</label>
              <input ref={register} type="email" className="form-control" id="email" name="email" />
            </div>

            <div className="form-row">
              <div className="col">
                <label htmlFor="firstName">First Name</label>
                <input ref={register} type="text" className="form-control" id="firstName" name="firstName" />
              </div>
              <div className="col">
                <label htmlFor="lastName">Last Name</label>
                <input ref={register} type="text" className="form-control" id="lastName" name="lastName" />
              </div>
            </div>

            <hr />
            <button type="submit" className="m-1 btn btn-success">
              Send Invitation
            </button>
            <Link className="btn btn-info" to="/dashboard">
              Back to Invitations
            </Link>
          </form>
        </>
      )}

      {isSubmitting && (
        <>
          <h3>Creating Invitation</h3>
          <hr />
          <div style={{ textAlign: "center " }}>
            Sending invitation, connecting to OneDrive, and creating folders...
          </div>
        </>
      )}

      {isSubmitted && (
        <>
          <h3>Invitation Created.</h3>
          <hr />
          <Link className="btn btn-info m-1" to="/dashboard">
            Back to Invitations
          </Link>
          <button className="btn btn-success m-1" onClick={() => setStatus("ready")}>
            Create Another Invitation
          </button>
        </>
      )}
    </Layout>
  )
}

export default CreateInvitation
